/**
 * Page anchor
 */
import * as React from "react";
interface Props {
  anchor?: string;
}

/**
 * Page anchor
 *
 * @param anchor text
 */
export const Anchor: React.FC<Props> = ({
  anchor
}: Props) => {
  if (anchor) {
    return <div className="block-anchor">
        <small>{anchor}</small>
      </div>;
  }
  return null;
};
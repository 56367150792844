"use client";

/**
 * Collapseable accordion block structure
 */
import { gql } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Collapse, Row } from "reactstrap";
import { Block } from "./_block";
import { Image, ImageBlock } from "./Image";
import { OwnersBlock, OwnersProps } from "./Owners";
import { FullWidthParagraphBlock, Paragraph } from "./Paragraph";
import { ParagraphCallout, ParagraphCalloutBlock } from "./ParagraphCallout";
import { ParagraphImage, ParagraphImageBlock } from "./ParagraphImage";
import { Partners, PartnersBlock } from "./Partners";
import { Quote, QuoteBlock } from "./Quote";
import { Steps, StepsBlock } from "./Steps";
interface AccordionSegmentProps {
  /** Title for an accordian segment */
  title: string;
  /** Streamblocks that make up the segment */
  parts: Array<Paragraph | ParagraphCallout | ParagraphImage | Steps | Quote | Image | Partners | OwnersProps>;
}
interface AccordionProps extends Block {
  /** Individual collapsable segments */
  segments: AccordionSegmentProps[];
}
export type Accordion = AccordionProps & Block;

/**
 * A single collapsable section of the acordion
 */
const AccordionSegment: React.FC<AccordionSegmentProps> = ({
  parts,
  title
}: AccordionSegmentProps) => {
  const fieldRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if ("#" === "#" + encodeURIComponent(title) && fieldRef.current) {
      fieldRef.current.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [title]);
  return <CardBody innerRef={fieldRef} data-sentry-element="CardBody" data-sentry-component="AccordionSegment" data-sentry-source-file="Accordion.tsx">
      {parts.map((block, index) => {
      switch (block.blockType) {
        case "steps":
          return <StepsBlock {...block as Steps} key={index} />;
        case "paragraph":
          return <FullWidthParagraphBlock {...block as Paragraph} key={index} />;
        case "paragraph_image":
          return <ParagraphImageBlock {...block as ParagraphImage} key={index} />;
        case "paragraph_callout":
          return <ParagraphCalloutBlock {...block as ParagraphCallout} key={index} />;
        case "quote":
          return <QuoteBlock {...block as Quote} key={index} />;
        case "image":
          return <ImageBlock {...block as Image} key={index} />;
        case "partners":
          return <PartnersBlock {...block as Partners} key={index} />;
        case "owners":
          return <OwnersBlock {...block as OwnersProps} key={index} />;
        default:
          return <React.Fragment />;
      }
    })}
    </CardBody>;
};

/**
 * Collapseable accordion block structure
 */
export const AccordionBlock: React.FC<AccordionProps> = ({
  segments
}: AccordionProps) => {
  // const { hash } = useLocation()
  const hashNumber = segments.map(s => "#" + encodeURIComponent(s.title)).indexOf("#");
  const [open, setOpen] = useState<number>(0 <= hashNumber ? hashNumber : 0);
  return <Row className="accordion-block" data-sentry-element="Row" data-sentry-component="AccordionBlock" data-sentry-source-file="Accordion.tsx">
      <Col md={{
      size: 10,
      offset: 1
    }} data-sentry-element="Col" data-sentry-source-file="Accordion.tsx">
        <Card data-sentry-element="Card" data-sentry-source-file="Accordion.tsx">
          {segments.map((segment, index) => <React.Fragment key={index}>
              <CardHeader onClick={() => setOpen(open !== index ? index : -1)} id={"#" + encodeURIComponent(segment.title)}>
                {segment.title} <span style={{
              float: "right"
            }}>{open === index ? "-" : "+"}</span>
              </CardHeader>
              <Collapse isOpen={open === index}>
                <AccordionSegment {...segment} />
              </Collapse>
            </React.Fragment>)}
        </Card>
      </Col>
    </Row>;
};

/**
 * GraphQL fragment for querying an Accordian Block.
 *
 * ... on AccordionBlock {
 *   ...AccordionBlockFragment
 * }
 */
export const AccordionBlockFragment = gql`
  fragment AccordionBlockFragment on AccordionBlock {
    blockType
    segments {
      title
      parts {
        ... on QuoteBlock {
          ...QuoteBlockFragment
        }
        ... on ParagraphBlock {
          ...ParagraphBlockFragment
        }
        ... on ParagraphImageBlock {
          ...ParagraphImageBlockFragment
        }
        ... on ParagraphCalloutBlock {
          ...ParagraphCalloutBlockFragment
        }
        ... on StepsBlock {
          ...StepsBlockFragment
        }
        ... on ImageBlock {
          ...ImageBlockFragment
        }
        ... on PartnersBlock {
          ...PartnersBlockFragment
        }
        ... on OwnersBlock {
          ...OwnersBlockFragment
        }
      }
    }
  }
`;
/**
 * A curated subset of projects
 */
import { gql } from "@apollo/client";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { paths } from "shared/constants";
import { urlReplacer } from "shared/urlParams";
import { Block } from "./_block";
import { Anchor } from "./Anchor";

/** A single project select to highlight */
export interface SelectedProject {
  /** Image to highlight project */
  image: {
    rendition: {
      url: string;
    };
  };
  /** Description of project */
  text: string;
  /** End of current project season for additional emphasis */
  endDate?: string;
  /** project selected */
  mission: {
    slug: string;
  };
}

/** A selection of projects to highlight */
interface SelectedProjectsProps {
  /** Optional anchor */
  anchor?: string;
  /** Title to group the projects under */
  title: string;
  /** Ordered list of projects selected */
  projects: SelectedProject[];
  /** Link URL, probably to group projects or similar */
  link: string;
  /** Link button text */
  linkText: string;
}
export type SelectedProjects = SelectedProjectsProps & Block;

/**
 * A curated subset of projects
 *
 * @param anchor
 * @param title
 * @param projects
 * @param link
 * @param linkText
 */
export const SelectedProjectsBlock: React.FC<SelectedProjectsProps> = ({
  anchor,
  title,
  projects,
  link,
  linkText
}: SelectedProjectsProps) => <Row data-sentry-element="Row" data-sentry-component="SelectedProjectsBlock" data-sentry-source-file="SelectedProjects.tsx">
    <Col xs={{
    size: 10,
    offset: 1
  }} className="selected-projects-block" data-sentry-element="Col" data-sentry-source-file="SelectedProjects.tsx">
      <Anchor anchor={anchor} data-sentry-element="Anchor" data-sentry-source-file="SelectedProjects.tsx" />
      <h3>{title}</h3>
      <Row data-sentry-element="Row" data-sentry-source-file="SelectedProjects.tsx">
        {projects.map((project, index) => <Col md={6} xl={3} key={index} style={{
        padding: "1rem"
      }}>
            <Link href={urlReplacer(paths.missions.mission, new Map([[":slug", project.mission.slug]]))}>
              <Image src={project.image.rendition.url} alt={project.text} className="img-fluid" {...imageDims} />
              <p>{project.text}</p>
            </Link>
          </Col>)}
      </Row>
      <Row data-sentry-element="Row" data-sentry-source-file="SelectedProjects.tsx">
        <Col md={12} className="text-center" data-sentry-element="Col" data-sentry-source-file="SelectedProjects.tsx">
          <Link href={link} data-sentry-element="Link" data-sentry-source-file="SelectedProjects.tsx">
            <Button data-sentry-element="Button" data-sentry-source-file="SelectedProjects.tsx">{linkText}</Button>
          </Link>
        </Col>
      </Row>
    </Col>
  </Row>;
const imageDims = {
  width: 400,
  height: 400
};

/**
 * GraphQL fragment to query Selected Projects block
 *
 * ... on SelectedProjectBlock {
 *   ...SelectedProjectBlockFragment
 * }
 */
export const SelectedProjectBlockFragment = gql`
  fragment SelectedProjectBlockFragment on SelectedProjectBlock {
    blockType
    anchor
    title
    link
    linkText
    projects {
      image {
        rendition(fill: "${imageDims.width}x${imageDims.height}") {
          url
        }
      }
      text
      endDate
      mission {
        slug
      }
    }
  }
`;
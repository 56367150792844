/**
 * Block with primary and sidebar content streams
 */
import { gql } from "@apollo/client";
import React from "react";
import { Col, Row } from "reactstrap";
import { Block } from "./_block";
import { Accordion, AccordionBlock } from "./Accordion";
import { Image, ImageBlock } from "./Image";
import { OwnersBlock, OwnersProps } from "./Owners";
import { FullWidthParagraphBlock, Paragraph } from "./Paragraph";
import { ParagraphCallout, ParagraphCalloutBlock } from "./ParagraphCallout";
import { ParagraphImage, ParagraphImageBlock } from "./ParagraphImage";
import { Partners, PartnersBlock } from "./Partners";
import { Quote, QuoteBlock } from "./Quote";
import { Steps, StepsBlock } from "./Steps";
interface MainContentProps {
  /** Stream for larger side of block */
  mainContents: Array<Paragraph | ParagraphCallout | ParagraphImage | Steps | Quote | Accordion | Image | Partners | OwnersProps>;
}
interface SidebarProps {
  /** Optional sidebar image */
  sidebarImage?: {
    rendition: {
      url: string;
    };
  };
  /** Sidebar background color */
  sidebarColor: string;
  /** Invert sidebar text colors */
  invertSidebarText: boolean;
  /** Stream for sidebar contents */
  sidebarContents: Array<Paragraph | Image>;
}
type InternalProps = MainContentProps & SidebarProps;
type WithSidebarProps = InternalProps & {
  /** Should the block be displayed at full width of it's parent */
  fullWidth: boolean;
};
export type WithSidebar = Block & WithSidebarProps;

/**
 * Main content stream
 */
const MainContent: React.FC<MainContentProps> = ({
  mainContents
}) => <Col md={7} className="main" data-sentry-element="Col" data-sentry-component="MainContent" data-sentry-source-file="WithSidebar.tsx">
    {mainContents.map((block, index) => {
    switch (block.blockType) {
      case "steps":
        return <StepsBlock {...block as Steps} key={index} />;
      case "paragraph":
        return <FullWidthParagraphBlock {...block as Paragraph} key={index} />;
      case "paragraph_image":
        return <ParagraphImageBlock {...block as ParagraphImage} key={index} />;
      case "paragraph_callout":
        return <ParagraphCalloutBlock {...block as ParagraphCallout} key={index} />;
      case "quote":
        return <QuoteBlock {...block as Quote} key={index} />;
      case "image":
        return <ImageBlock {...block as Image} key={index} />;
      case "accordion":
        return <AccordionBlock {...block as Accordion} key={index} />;
      case "partners":
        return <PartnersBlock {...block as Partners} key={index} />;
      case "owners":
        return <OwnersBlock {...block as OwnersProps} key={index} />;
      default:
        return <React.Fragment />;
    }
  })}
  </Col>;

/**
 * Sidebar column
 */
const Sidebar: React.FC<SidebarProps> = ({
  sidebarColor,
  sidebarImage,
  sidebarContents,
  invertSidebarText
}) => <Col md={5} style={{
  backgroundColor: sidebarColor
}} className={"sidebar" + (invertSidebarText ? " invert-paragraph " : "")} data-sentry-element="Col" data-sentry-component="Sidebar" data-sentry-source-file="WithSidebar.tsx">
    {sidebarImage ? <img src={sidebarImage.rendition.url} alt="sidebar" className="img-fluid sidebar-header" /> : null}
    <div className="sidebar-contents">
      {sidebarContents.map((block, index) => {
      switch (block.blockType) {
        case "paragraph":
          return <FullWidthParagraphBlock {...block as Paragraph} key={index} />;
        case "image":
          return <ImageBlock {...block as Image} key={index} />;
        default:
          return <React.Fragment />;
      }
    })}
    </div>
  </Col>;

/**
 * Main and sidebar content streams and formatting
 */
const Internal: React.FC<InternalProps> = ({
  mainContents,
  ...sidebar
}) => <Row data-sentry-element="Row" data-sentry-component="Internal" data-sentry-source-file="WithSidebar.tsx">
    <MainContent {...{
    mainContents
  }} data-sentry-element="MainContent" data-sentry-source-file="WithSidebar.tsx" />

    <Sidebar {...sidebar} data-sentry-element="Sidebar" data-sentry-source-file="WithSidebar.tsx" />
  </Row>;

/**
 * Block with primary and sidebar content streams
 */
export const WithSidebarBlock: React.FC<WithSidebarProps> = ({
  fullWidth,
  ...props
}) => <div className="with-sidebar-block" data-sentry-component="WithSidebarBlock" data-sentry-source-file="WithSidebar.tsx">
    {fullWidth ? <Internal {...props} /> : <Row className="with-sidebar-row">
        <Col md={{
      offset: 1,
      size: 10
    }}>
          <Internal {...props} />
        </Col>
      </Row>}
  </div>;

/**
 * GraphQL fragment to query a With Sidebar block
 *
 * ... on WithSidebarBlock {
 *   ...WithSidebarBlockFragment
 * }
 */
export const WithSidebarBlockFragment = gql`
  fragment WithSidebarBlockFragment on WithSidebarBlock {
    blockType
    fullWidth
    mainContents {
      ... on QuoteBlock {
        ...QuoteBlockFragment
      }
      ... on ParagraphBlock {
        ...ParagraphBlockFragment
      }
      ... on ParagraphImageBlock {
        ...ParagraphImageBlockFragment
      }
      ... on ParagraphCalloutBlock {
        ...ParagraphCalloutBlockFragment
      }
      ... on StepsBlock {
        ...StepsBlockFragment
      }
      ... on ImageBlock {
        blockType
        image {
          rendition(width: 1000) {
            url
          }
        }
        alternateText
      }
      ... on AccordionBlock {
        ...AccordionBlockFragment
      }
      ... on PartnersBlock {
        ...PartnersBlockFragment
      }
      ... on OwnersBlock {
        ...OwnersBlockFragment
      }
    }
    sidebarImage {
      rendition(width: 600) {
        url
      }
    }
    sidebarColor
    invertSidebarText
    sidebarContents {
      ... on ParagraphBlock {
        ...ParagraphBlockFragment
      }
      ... on ImageBlock {
        blockType
        image {
          rendition(width: 600) {
            url
          }
        }
        alternateText
      }
    }
  }
`;
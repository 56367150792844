/**
 * Paragraph with blockquote/callout
 */
import { gql } from "@apollo/client";
import React from "react";
import { Col, Row } from "reactstrap";
import { Anchor } from "./Anchor";
import { Paragraph, ParagraphInner } from "./Paragraph";
export interface ParagraphCallout extends Paragraph {
  /** Callout or quote to show alongside paragraph */
  callout: string;
  /** Optional attribution for the callout */
  attribution?: string;
}
type ParagraphCalloutProps = Omit<ParagraphCallout, "blockType">;

/**
 *
 * @param anchor optional page anchor
 * @param title Optional title
 * @param paragraph Text
 * @param rightLayout Should the paragraph be aligned to the right
 * @param callout Blockquote
 * @param attribution Optional attribution for quote
 * @param link Optional link URL
 * @param linkText Optional link button text
 */
export const ParagraphCalloutBlock: React.FC<ParagraphCalloutProps> = ({
  anchor,
  title,
  paragraph,
  rightLayout,
  callout,
  attribution,
  link,
  linkText
}: ParagraphCalloutProps) => <Row data-sentry-element="Row" data-sentry-component="ParagraphCalloutBlock" data-sentry-source-file="ParagraphCallout.tsx">
    <Col xs={{
    size: 10,
    offset: 1
  }} className="paragraph-callout-block" data-sentry-element="Col" data-sentry-source-file="ParagraphCallout.tsx">
      <Anchor {...{
      anchor
    }} data-sentry-element="Anchor" data-sentry-source-file="ParagraphCallout.tsx" />
      {title ? <h3>{title}</h3> : null}
      <Row data-sentry-element="Row" data-sentry-source-file="ParagraphCallout.tsx">
        {rightLayout ? null : <Col md={6}>
            <ParagraphInner {...{
          paragraph,
          link,
          linkText
        }} />
          </Col>}

        <Col md={6} data-sentry-element="Col" data-sentry-source-file="ParagraphCallout.tsx">
          <blockquote>{callout}</blockquote>
          {attribution ? <small>{attribution}</small> : null}
        </Col>

        {rightLayout ? <Col md={6}>
            <ParagraphInner {...{
          paragraph,
          link,
          linkText
        }} />
          </Col> : null}
      </Row>
    </Col>
  </Row>;

/**
 * GraphQL fragment for Paragraph Callout blocks
 *
 * ... on ParagraphCalloutBlock {
 *   ...ParagraphCalloutBlockFragment
 * }
 */
export const ParagraphCalloutBlockFragment = gql`
  fragment ParagraphCalloutBlockFragment on ParagraphCalloutBlock {
    blockType
    anchor
    title
    paragraph
    rightLayout
    callout
    attribution
    link
    linkText
  }
`;
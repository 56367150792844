/**
 * Selection of projects that are filtered by if they are
 * designed for groups or for individuals
 */
import { gql } from "@apollo/client";
import React from "react";
import { Col, Row } from "reactstrap";
import { FilteredMissionList } from "Core/Project/List";
import { Block } from "./_block";
import { Anchor } from "./Anchor";
interface FilteredProjectsProps {
  /** Optional anchor tag */
  anchor?: string;
  /** Should projects for the public not be shown */
  notIndividuals?: boolean;
  /** Should projects for groups not be shown */
  notGroups?: boolean;
}
export type FilteredProjects = FilteredProjectsProps & Block;

/**
 * Selection of projects that are filtered by if they are
 * designed for groups or for individuals
 *
 * @param anchor anchor text to display
 * @param filters Options for filtering projects
 */
export const FilteredProjectsBlock: React.FC<FilteredProjectsProps> = ({
  anchor,
  ...filters
}: FilteredProjectsProps) => <Row style={{
  paddingTop: "1rem"
}} data-sentry-element="Row" data-sentry-component="FilteredProjectsBlock" data-sentry-source-file="FilteredProjects.tsx">
    <Anchor {...{
    anchor
  }} data-sentry-element="Anchor" data-sentry-source-file="FilteredProjects.tsx" />
    <Col xs={{
    size: 10,
    offset: 1
  }} className="filtered-projects-block" data-sentry-element="Col" data-sentry-source-file="FilteredProjects.tsx">
      <FilteredMissionList {...filters} data-sentry-element="FilteredMissionList" data-sentry-source-file="FilteredProjects.tsx" />
    </Col>
  </Row>;

/**
 * GraphQL fragment for quering a Filtered Projects Block
 *
 * ... on FilteredProjectsBlock {
 *  ..FilteredProjectsBlockFragment
 * }
 */
export const FilteredProjectsBlockFragment = gql`
  fragment FilteredProjectsBlockFragment on FilteredProjectsBlock {
    blockType
    anchor
    notClassroom
    notIndividuals
  }
`;
/**
 * A list of steps for participants to complete
 */
import { gql } from "@apollo/client";
import Image from "next/image";
import React from "react";
import { Col, Row } from "reactstrap";
import { Block } from "./_block";
import { Anchor } from "./Anchor";

/** A single step */
export interface Step {
  /** Circled image */
  image: {
    rendition: {
      url: string;
    };
  };
  /** Step description */
  text: string;
  /** Optional link URL */
  link?: string;
  /** Step title */
  title: string;
}

/** A group of steps for participants to do */
interface StepsProps {
  /** Optional anchor */
  anchor?: string;
  /** Optional title */
  title?: string;
  /** Ordered list of steps */
  steps: Step[];
}
export type Steps = StepsProps & Block;

/**
 *
 * @param anchor Optional anchor
 * @param title Title
 * @param steps Steps for participant to complete
 */
export const StepsBlock: React.FC<StepsProps> = ({
  anchor,
  title,
  steps
}: StepsProps) => <Row className="steps-block" data-sentry-element="Row" data-sentry-component="StepsBlock" data-sentry-source-file="Steps.tsx">
    <Col xs={{
    size: 10,
    offset: 1
  }} data-sentry-element="Col" data-sentry-source-file="Steps.tsx">
      <Anchor anchor={anchor} data-sentry-element="Anchor" data-sentry-source-file="Steps.tsx" />
      {title ? <h3>{title}</h3> : null}
      <Row data-sentry-element="Row" data-sentry-source-file="Steps.tsx">
        {steps.map((step, index) => <Col md={6} key={index} className="block-step">
            <a href={step.link ? step.link : undefined}>
              <Row>
                <Col sm={6} lg={4}>
                  <Image src={step.image.rendition.url} alt={step.text} className="img-fluid" {...imageDims} />
                </Col>
                <Col sm={6} lg={8} className="step-details">
                  <Row>
                    <Col xs={8} sm={12}>
                      <div className="step-bar" />
                      <h6>{step.title}</h6>
                      {step.text}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </a>
          </Col>)}
      </Row>
    </Col>
  </Row>;
const imageDims = {
  width: 230,
  height: 230
};

/**
 * GraphQL fragment to load Steps blocks
 *
 * ... on StepsBlock {
 *   ...StepsBlockFragment
 * }
 */
export const StepsBlockFragment = gql`
  fragment StepsBlockFragment on StepsBlock {
    blockType
    anchor
    title
    steps {
      title
      text
      link
      image {
        rendition(fill: "${imageDims.width}x${imageDims.height}") {
          url
        }
      }
    }
  }
`;
/**
 * Block to display a specific featured project with a full width background
 */
import { gql } from "@apollo/client";
import Link from "next/link";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { paths } from "shared/constants";
import { urlReplacer } from "shared/urlParams";
import { Block } from "./_block";
import { Anchor } from "./Anchor";

/** Highlight a specific project */
export interface FeaturedProjectProps {
  /** Optional anchor */
  anchor?: string;
  /** Title to get folks stoked on the project */
  title: string;
  /** Description of the project */
  blurb: string;
  /** Optionally override the link text to the project */
  linkText?: string;
  /** Invert the text color if the background image is dark */
  darkBackground: boolean;
  /** Full width background image */
  image: {
    rendition: {
      url: string;
    };
  };
  /** Mission information */
  mission: {
    slug: string;
  };
  /** Align text to the right when the focus of the background image is too the left */
  rightLayout: boolean;
}
export type FeaturedProject = FeaturedProjectProps & Block;

/**
 * Block to display a specific featured project with a full width background
 */
export const FeaturedProjectBlock: React.FC<FeaturedProjectProps> = ({
  anchor,
  title,
  blurb,
  linkText,
  rightLayout,
  image,
  mission,
  darkBackground
}: FeaturedProjectProps) => <div className="featured-project-block" style={{
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("' + image.rendition.url + '")',
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover"
}} data-sentry-component="FeaturedProjectBlock" data-sentry-source-file="FeaturedProject.tsx">
    <Row data-sentry-element="Row" data-sentry-source-file="FeaturedProject.tsx">
      <Col xs={{
      size: 10,
      offset: 1
    }} data-sentry-element="Col" data-sentry-source-file="FeaturedProject.tsx">
        <Anchor anchor={anchor} data-sentry-element="Anchor" data-sentry-source-file="FeaturedProject.tsx" />
        <Row style={{
        textAlign: rightLayout ? "right" : "left"
      }} data-sentry-element="Row" data-sentry-source-file="FeaturedProject.tsx">
          <Col md={{
          size: 6,
          offset: rightLayout ? 6 : 0
        }} style={{
          paddingTop: "1rem",
          paddingBottom: "1rem"
        }} data-sentry-element="Col" data-sentry-source-file="FeaturedProject.tsx">
            <h2 style={{
            color: darkBackground ? "white" : "black"
          }}>{title}</h2>
            <h6 style={{
            color: darkBackground ? "white" : "black"
          }}>{blurb}</h6>
            <Link href={urlReplacer(paths.missions.mission, new Map([[":slug", mission.slug]]))} data-sentry-element="Link" data-sentry-source-file="FeaturedProject.tsx">
              <Button data-sentry-element="Button" data-sentry-source-file="FeaturedProject.tsx">{linkText ? linkText : "Learn More"}</Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>;

/**
 * GraphQL fragment for querying an Featured Project Block.
 *
 * .... on FeaturedProjectBlock {
 *  ...FeaturedProjectBlockFragment
 * }
 */
export const FeaturedProjectBlockFragment = gql`
  fragment FeaturedProjectBlockFragment on FeaturedProjectBlock {
    blockType
    anchor
    mission {
      slug
    }
    title
    blurb
    linkText
    image {
      rendition(fill: "1200x400") {
        url
      }
    }
    rightLayout
    darkBackground
  }
`;
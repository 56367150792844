/**
 * Display a list of selected partner organizations
 */
import { gql } from "@apollo/client";
import React from "react";
import { Col, Row } from "reactstrap";
import { Block } from "./_block";

/**
 * Selected partner organization
 */
interface SelectedPartner {
  /** URL to partners website */
  url: string;
  /** Full name of organization */
  name: string;
  /** Logo URL for partner organization */
  logo: string;
  /** Unique slug for local page */
  slug: string;
}

/**
 * Block to display manually selected partner organizations
 */
interface PartnersProps {
  /** Title text to display in block */
  title: string;
  /** List of selected partners */
  partners: SelectedPartner[];
}
export type Partners = PartnersProps & Block;

/**
 * Display a single partner
 */
const Partner: React.FC<SelectedPartner> = ({
  url,
  logo,
  name
}) => <Col md={4} className="partner" data-sentry-element="Col" data-sentry-component="Partner" data-sentry-source-file="Partners.tsx">
    <a href={url}>
      <img src={logo} alt={name} />
    </a>
  </Col>;

/**
 * Display a list of selected partner organizations
 */
export const PartnersBlock: React.FC<PartnersProps> = ({
  title,
  partners
}) => <Row className="partners-block" data-sentry-element="Row" data-sentry-component="PartnersBlock" data-sentry-source-file="Partners.tsx">
    <Col md={{
    offset: 1,
    size: 10
  }} data-sentry-element="Col" data-sentry-source-file="Partners.tsx">
      <h3>{title}</h3>
      <Row data-sentry-element="Row" data-sentry-source-file="Partners.tsx">
        {partners.map((partner, index) => <Partner {...partner} key={index} />)}
      </Row>
    </Col>
  </Row>;

/**
 * GraphQL fragment to query Partner blocks
 *
 * ... on PartnersBlock {
 *  ...PartnersBlockFragment
 * }
 */
export const PartnersBlockFragment = gql`
  fragment PartnersBlockFragment on PartnersBlock {
    blockType
    title
    partners {
      name
      logo
      url
    }
  }
`;
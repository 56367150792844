/**
 * Full width paragraph block
 */
import { gql } from "@apollo/client";
import React from "react";
import { Col, Row } from "reactstrap";
import { ParagraphInner } from "./Paragraph";
import { ParagraphImage } from "./ParagraphImage";
export interface ParagraphImageFull extends ParagraphImage {
  /** Color to display behind text */
  backgroundColor: string;
  /** Color for title */
  titleColor: string;
  /** Should the paragraph colors be inverted due to a light background */
  invertText: boolean;
}
type ParagraphImageFullProps = Omit<ParagraphImageFull, "blockType">;
type ImageColBaseProps = Pick<ParagraphImageFullProps, "image">;
interface ImageColProps extends ImageColBaseProps {
  alt: string;
}

/**
 * Component to display image at full width of a column
 *
 * @param image Image rendition with url to display
 */
const ImageCol: React.FC<ImageColProps> = ({
  image,
  alt
}: ImageColProps) => <Col md={7} className="img-col" data-sentry-element="Col" data-sentry-component="ImageCol" data-sentry-source-file="ParagraphImageFull.tsx">
    <img className="img-fluid" alt={alt} src={image.rendition.url} />
  </Col>;

/**
 * Full width image block
 *
 * @param title Optional title
 * @param paragraph Text
 * @param image URL of image to display
 * @param rightLayout Should the text be aligned to the right
 * @param link Optional link URL
 * @param linkText Optional link button text
 * @param backgroundColor Color for the background
 * @param titleColor Color for the title
 */
export const ParagraphImageFullBlock: React.FC<ParagraphImageFullProps> = ({
  title,
  paragraph,
  image,
  rightLayout,
  link,
  linkText,
  backgroundColor,
  titleColor,
  invertText
}: ParagraphImageFullProps) => <Row className={"paragraph-image-full" + (invertText ? " invert-paragraph " : "")} style={{
  backgroundColor
}} data-sentry-element="Row" data-sentry-component="ParagraphImageFullBlock" data-sentry-source-file="ParagraphImageFull.tsx">
    {rightLayout ? <ImageCol image={image} alt={title ?? paragraph} /> : null}

    <Col md={5} data-sentry-element="Col" data-sentry-source-file="ParagraphImageFull.tsx">
      <div className="text-col">
        {title ? <h3 style={{
        color: titleColor
      }}>{title}</h3> : null}
        <ParagraphInner {...{
        paragraph,
        link,
        linkText
      }} data-sentry-element="ParagraphInner" data-sentry-source-file="ParagraphImageFull.tsx" />
      </div>
    </Col>

    {!rightLayout ? <ImageCol image={image} alt={title ?? paragraph} /> : null}
  </Row>;

/**
 * GraphQL fragment for quering Paragraph Full Image blocks
 *
 * ... on ParagraphImageFullBlock {
 *  ...ParagraphImageFullBlockFragment
 * }
 */
export const ParagraphImageFullBlockFragment = gql`
  fragment ParagraphImageFullBlockFragment on ParagraphImageFullBlock {
    blockType
    anchor
    title
    paragraph
    rightLayout
    link
    linkText
    backgroundColor
    invertText
    titleColor
    image {
      rendition(max: "1200x1200") {
        url
      }
    }
  }
`;
/**
 * Basic block with paragraph
 */
import { gql } from "@apollo/client";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Block } from "./_block";
import { Anchor } from "./Anchor";
interface ParagraphInnerProps {
  /** Paragraph HTML */
  paragraph: string;
  /** Optional link URL */
  link?: string;
  /** Optional link button text */
  linkText?: string;
}

/** A relatively simple paragraph */
export interface ParagraphProps extends ParagraphInnerProps {
  /** Optional anchor text */
  anchor?: string;
  /** Optional title text */
  title?: string;
  /** Align text to the right  */
  rightLayout?: boolean;
}
export type Paragraph = ParagraphProps & Block;

/** Formatted paragraph and link button */
export const ParagraphInner: React.FC<ParagraphInnerProps> = ({
  paragraph,
  link,
  linkText
}) => <React.Fragment>
    <div dangerouslySetInnerHTML={{
    __html: paragraph
  }} />
    {link && linkText ? <a href={link}>
        <Button>{linkText}</Button>
      </a> : null}
  </React.Fragment>;

/**
 *
 * @param anchor Optional page anchor
 * @param title Optional title
 * @param paragraph Text for paragraph
 * @param rightLayout Should the text be aligned to the right
 * @param link Optional link URL
 * @param linkText Optional link button text
 */
export const ParagraphBlock: React.FC<ParagraphProps> = ({
  anchor,
  title,
  paragraph,
  rightLayout,
  link,
  linkText
}: ParagraphProps) => <Row data-sentry-element="Row" data-sentry-component="ParagraphBlock" data-sentry-source-file="Paragraph.tsx">
    <Col xs={{
    size: 10,
    offset: 1
  }} className="paragraph-block" data-sentry-element="Col" data-sentry-source-file="Paragraph.tsx">
      <Anchor {...{
      anchor
    }} data-sentry-element="Anchor" data-sentry-source-file="Paragraph.tsx" />
      {title ? <h3>{title}</h3> : null}
      <Row data-sentry-element="Row" data-sentry-source-file="Paragraph.tsx">
        <Col md={{
        size: 8,
        offset: rightLayout ? 4 : 0
      }} data-sentry-element="Col" data-sentry-source-file="Paragraph.tsx">
          <ParagraphInner {...{
          paragraph,
          link,
          linkText
        }} data-sentry-element="ParagraphInner" data-sentry-source-file="Paragraph.tsx" />
        </Col>
      </Row>
    </Col>
  </Row>;

/** A full width paragraph (aka you can't align it) for smaller width components */
export const FullWidthParagraphBlock: React.FC<ParagraphProps> = ({
  title,
  paragraph,
  link,
  linkText
}) => {
  return <Row data-sentry-element="Row" data-sentry-component="FullWidthParagraphBlock" data-sentry-source-file="Paragraph.tsx">
      <Col xs={{
      size: 10,
      offset: 1
    }} className="paragraph-block" data-sentry-element="Col" data-sentry-source-file="Paragraph.tsx">
        {title ? <h3>{title}</h3> : null}
        <ParagraphInner {...{
        paragraph,
        link,
        linkText
      }} data-sentry-element="ParagraphInner" data-sentry-source-file="Paragraph.tsx" />
      </Col>
    </Row>;
};
/**
 * GraphQL fragment for quering Paragraph blocks
 *
 * ... on ParagraphBlock {
 *  ...ParagraphBlockFragment
 * }
 */
export const ParagraphBlockFragment = gql`
  fragment ParagraphBlockFragment on ParagraphBlock {
    blockType
    anchor
    title
    paragraph
    rightLayout
    link
    linkText
  }
`;
/**
 * Block to display a single full width image with alternate text
 */
import { gql } from "@apollo/client";
import React from "react";
import { Block } from "./_block";
interface ImageProps {
  /** Full width background image */
  image: {
    rendition: {
      url: string;
    };
  };
  /** Alternate text for hover over or screen readers */
  alternateText: string;
}
export type Image = ImageProps & Block;

/**
 * Block to display a single full width image with alternate text
 */
export const ImageBlock: React.FC<ImageProps> = ({
  image,
  alternateText
}) => <img src={image.rendition.url} alt={alternateText} className="img-fluid" data-sentry-component="ImageBlock" data-sentry-source-file="Image.tsx" />;

/**
 * GraphQL fragment for Image block
 *
 * ... on ImageBlock {
 *  ...ImageBlockFragment
 * }
 */
export const ImageBlockFragment = gql`
  fragment ImageBlockFragment on ImageBlock {
    blockType
    image {
      rendition(width: 1600) {
        url
      }
    }
    alternateText
  }
`;
/**
 * Paragraph block with image to the side
 */
import { gql } from "@apollo/client";
import Image from "next/image";
import React from "react";
import { Col, Row } from "reactstrap";
import { Anchor } from "./Anchor";
import { Paragraph, ParagraphInner } from "./Paragraph";
export interface ParagraphImage extends Paragraph {
  /** Image to to the side of the block */
  image: {
    rendition: {
      url: string;
    };
  };
}
type ParagraphImageProps = Omit<ParagraphImage, "blockType">;

/**
 * Paragraph block with image to the side
 *
 * @param anchor Optional page anchor
 * @param title Optional title
 * @param paragraph Text
 * @param image URL of image to display
 * @param rightLayout Should the text be aligned to the right
 * @param link Optional link URL
 * @param linkText Optional link button text
 */
export const ParagraphImageBlock: React.FC<ParagraphImageProps> = ({
  anchor,
  title,
  paragraph,
  image,
  rightLayout,
  link,
  linkText
}: ParagraphImageProps) => <Row data-sentry-element="Row" data-sentry-component="ParagraphImageBlock" data-sentry-source-file="ParagraphImage.tsx">
    <Col xs={{
    size: 10,
    offset: 1
  }} className="paragraph-image-block" data-sentry-element="Col" data-sentry-source-file="ParagraphImage.tsx">
      <Anchor {...{
      anchor
    }} data-sentry-element="Anchor" data-sentry-source-file="ParagraphImage.tsx" />

      <Row data-sentry-element="Row" data-sentry-source-file="ParagraphImage.tsx">
        {rightLayout ? <Col md={6}>
            <Image src={image.rendition.url} alt={paragraph} className="img-fluid" {...imageDims} />
          </Col> : null}

        <Col md={6} data-sentry-element="Col" data-sentry-source-file="ParagraphImage.tsx">
          {title ? <h3>{title}</h3> : null}
          <ParagraphInner {...{
          paragraph,
          link,
          linkText
        }} data-sentry-element="ParagraphInner" data-sentry-source-file="ParagraphImage.tsx" />
        </Col>

        {rightLayout ? null : <Col md={6}>
            <Image src={image.rendition.url} alt={paragraph} className="img-fluid" {...imageDims} />
          </Col>}
      </Row>
    </Col>
  </Row>;
const imageDims = {
  width: 400,
  height: 400
};

/**
 * GraphQL fragment for quering a Paragraph Image Block
 *
 * ... on ParagraphImageBlock {
 *   ...ParagraphImageBlockFragment
 * }
 */
export const ParagraphImageBlockFragment = gql`
  fragment ParagraphImageBlockFragment on ParagraphImageBlock {
    blockType
    anchor
    title
    paragraph
    rightLayout
    link
    linkText
    image {
      rendition(fill: "${imageDims.width}x${imageDims.height}") {
        url
      }
    }
  }
`;
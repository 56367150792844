/**
 * Quote block with optional attribution and link
 */
import { gql } from "@apollo/client";
import Link from "next/link";
import React from "react";
import { Col, Row } from "reactstrap";
import { Block } from "./_block";
import { QuoteSVG } from "../../images/quoteSVG";
interface QuoteInnerProps {
  /** Quote text to display */
  quote: string;
  /** Optional quote attribution */
  attribution?: string;
}
interface QuoteProps extends QuoteInnerProps {
  /** Optional link URL */
  link?: string;
}
export type Quote = QuoteProps & Block;

/**
 * Inner structure of quote block
 *
 * @param quote quote text
 * @param attribution Optional attribution
 */
const QuoteInner: React.FC<QuoteInnerProps> = ({
  quote,
  attribution
}) => <React.Fragment>
    <Row data-sentry-element="Row" data-sentry-source-file="Quote.tsx">
      <Col className="quote-em" data-sentry-element="Col" data-sentry-source-file="Quote.tsx">
        <QuoteSVG data-sentry-element="QuoteSVG" data-sentry-source-file="Quote.tsx" />
      </Col>
    </Row>
    <Row data-sentry-element="Row" data-sentry-source-file="Quote.tsx">
      <Col data-sentry-element="Col" data-sentry-source-file="Quote.tsx">
        <blockquote>{quote}</blockquote>
      </Col>
    </Row>
    <Row data-sentry-element="Row" data-sentry-source-file="Quote.tsx">
      <Col sm={{
      size: 8,
      offset: 2
    }} md={{
      size: 4,
      offset: 4
    }} data-sentry-element="Col" data-sentry-source-file="Quote.tsx">
        {attribution ? <div dangerouslySetInnerHTML={{
        __html: attribution
      }} /> : null}
      </Col>
    </Row>
  </React.Fragment>;

/**
 * Quote block with optional attribution and link
 *
 * @param quote Quote text
 * @param link Optional URL to link to
 * @param attribution Optional attribution
 */
export const QuoteBlock: React.FC<QuoteProps> = ({
  quote,
  link,
  attribution
}: QuoteProps) => <Row className="quote-block" data-sentry-element="Row" data-sentry-component="QuoteBlock" data-sentry-source-file="Quote.tsx">
    <Col xs={{
    size: 10,
    offset: 1
  }} data-sentry-element="Col" data-sentry-source-file="Quote.tsx">
      {link ? <Link href={link}>
          <QuoteInner {...{
        quote,
        attribution
      }} />
        </Link> : <QuoteInner {...{
      quote,
      attribution
    }} />}
    </Col>
  </Row>;

/**
 * GraphQL fragment to query Quote blocks
 *
 * ... on QuoteBlock {
          ...QuoteBlockFragment
        }
 */
export const QuoteBlockFragment = gql`
  fragment QuoteBlockFragment on QuoteBlock {
    blockType
    quote
    link
    attribution
  }
`;
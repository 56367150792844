/**
 * A block to display project owners, or other cases where a user with
 * an extended profile may have an relationship to a project or the overall site.
 */
import { gql } from "@apollo/client";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Col, Row } from "reactstrap";
import { paths } from "shared/constants";
import { urlReplacer } from "shared/urlParams";
import { Block } from "./_block";
interface Profile {
  /** Unique user specific URL slug */
  slug: string;
  user: {
    /** User's first name */
    firstName: string;
    /** Users last name */
    lastName: string;
  };
  /** Selected photo of the user */
  photo: {
    rendition: {
      url: string;
    };
  };
}
interface OwnerSingle {
  /** Selected user */
  owner: Profile;
  /** Short description of user, often their organization */
  byline: string;
}
interface OwnersPropsBase {
  /** Title text to display in block */
  title: string;
  /** Project owner users and their organizations */
  owners: OwnerSingle[];
}
export type OwnersProps = OwnersPropsBase & Block;

/**
 * Display a single owner
 */
const Owner: React.FC<OwnerSingle> = ({
  owner,
  byline
}: OwnerSingle) => <Col className="owner" md={4} data-sentry-element="Col" data-sentry-component="Owner" data-sentry-source-file="Owners.tsx">
    <Row data-sentry-element="Row" data-sentry-source-file="Owners.tsx">
      <Col xs={4} data-sentry-element="Col" data-sentry-source-file="Owners.tsx">
        <Image src={owner.photo.rendition.url} alt={owner.user.firstName} className="img-fluid" {...imageDims} data-sentry-element="Image" data-sentry-source-file="Owners.tsx" />
      </Col>
      <Col className="align-self-center" data-sentry-element="Col" data-sentry-source-file="Owners.tsx">
        <div>
          <p>
            <b>
              {owner.user.firstName} {owner.user.lastName}
            </b>
          </p>
          <p>{byline}</p>
          <Link href={urlReplacer(paths.profile, new Map([[":slug", owner.slug]]))} data-sentry-element="Link" data-sentry-source-file="Owners.tsx">View Bio</Link>
        </div>
      </Col>
    </Row>
  </Col>;

/**
 * A block to display project owners, or other cases where a user with
 * an extended profile may have an relationship to a project or the overall site.
 */
export const OwnersBlock: React.FC<OwnersPropsBase> = ({
  title,
  owners
}: OwnersPropsBase) => <Row className="owners-block" data-sentry-element="Row" data-sentry-component="OwnersBlock" data-sentry-source-file="Owners.tsx">
    <Col md={{
    offset: 1,
    size: 10
  }} data-sentry-element="Col" data-sentry-source-file="Owners.tsx">
      <h3>{title}</h3>
      <Row data-sentry-element="Row" data-sentry-source-file="Owners.tsx">
        {owners.map((owner, index) => <Owner {...owner} key={index} />)}
      </Row>
    </Col>
  </Row>;
const imageDims = {
  width: 400,
  height: 400
};

/**
 * GraphQL fragment for quering an Owners Block
 *
 * ... on OwnersBlock {
 *  ...OwnersBlockFragment
 * }
 */
export const OwnersBlockFragment = gql`
  fragment OwnersBlockFragment on OwnersBlock {
    blockType
    title
    owners {
      owner {
        slug
        user {
          firstName
          lastName
        }
        photo {
          rendition(fill: "${imageDims.width}x${imageDims.height}") {
            url
          }
        }
      }
      byline
    }
  }
`;